<template>
  <v-overlay :value="show">
    <v-card
      width="1300px"
      light
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <h3>Поручение на экспедирование</h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div
        class="white pa-8 rounded-0 overflow-y-auto"
        style="height: 700px"
      >
        <v-row class="statistics-box mb-2">
          <v-col
            cols="12"
            class="pb-0"
          >
            <div class="font-weight-bold d-flex align-center">
              Договор
            </div>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.number_peg"
              label="Номер поручения"
              outlined
              background-color="white"
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="form.ueo_doc_number"
              label="Номер договора транспортной экспедиции"
              outlined
              background-color="white"
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col cols="3">
            <custom-date-picker
              v-model="form.ueo_doc_d_on"
              label="Дата договора транспортной экспедиции"
            />
          </v-col>
          <v-col
            cols="3"
            class="pb-0"
          >
            <v-text-field
              v-model="form.city_loading_location"
              label="Место оформления"
              outlined
              dense
              background-color="white"
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <v-row class="statistics-box mb-2">
          <v-col
            cols="12"
            class="pb-0"
          >
            <div class="font-weight-bold d-flex align-center">
              Водитель
            </div>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :value="driver"
              background-color="grey lighten-2"
              disabled
              label="ФИО водителя"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="form.driver_auth_doc_number"
              label="Номер доверенности водителя"
              outlined
              background-color="white"
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col cols="4">
            <custom-date-picker
              v-model="form.driver_auth_doc_d_on"
              label="Дата доверенности водителя"
            />
          </v-col>
        </v-row>

        <v-row class="statistics-box mb-2">
          <v-col
            cols="12"
            class="pb-0"
          >
            <div class="font-weight-bold d-flex align-center">
              Место и дата пересечения границы
            </div>
          </v-col>
          <v-col
            cols="4"
            class="pb-0"
          >
            <v-autocomplete
              v-model="dispatchCustomsCode"
              label="Пункт ввоза"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              item-text="text"
              item-value="code"
              :items="customsPoints"
              :filter="filterBySearchField"
            />
          </v-col>
          <v-col
            cols="4"
          >
            <custom-date-picker
              v-model="form.arrival_date"
              label="Дата прибытия в пункт ввоза"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="form.delivery_address"
              label="Адрес места доставки груза (место разгрузки)"
              outlined
              background-color="white"
              dense
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row class="statistics-box mb-2">
          <v-col
            cols="12"
            class="pb-0"
          >
            <div class="font-weight-bold d-flex align-center">
              Перечень транспортно-экспедиционных услуг
            </div>
          </v-col>
          <v-col
            cols="12"
            class="pa-0"
          >
            <services v-model="form.services" />
          </v-col>
        </v-row>

        <v-row class="statistics-box mb-2">
          <v-col
            cols="12"
            class="pb-0"
          >
            <div class="font-weight-bold d-flex align-center">
              Размер вознаграждения
            </div>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="form.currency_letter"
              :items="currencies"
              label="Валюта"
              item-text="letterCode"
              item-value="letterCode"
              hide-details
              outlined
              dense
              background-color="white"
              :filter="filterBySearchField"
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="form.invoices_numbers"
              outlined
              label="Номера выставленных счетов фактур"
              background-color="white"
              dense
              hide-details
            />
          </v-col>
        </v-row>



        <v-row class="statistics-box mb-2">
          <v-col
            cols="12"
            class="pb-0"
          >
            <v-textarea
              v-model="form.other_terms_shipment"
              rows="2"
              label="Особые условия перевозки, примечания ..."
              outlined
              background-color="white"
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="form.add_info"
              rows="2"
              label="Дополнительная ответсвтенность в соответствии ..."
              outlined
              background-color="white"
              dense
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </div>
      <v-col class="d-flex justify-end ">
        <v-btn
          color="#5CB7B1"
          elevation="0"
          darks
          @click="submit"
        >
          Cформировать
        </v-btn>
      </v-col>
    </v-card>
  </v-overlay>
</template>
<script>


import {mapGetters} from "vuex";
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import moment from "moment/moment";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import Services from "@/components/transit/forwarding-order/services.vue";
import {getCustomsNameByCode} from "@/helpers/catalogs";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";

export default {
  components: {Services, CustomDatePicker},
  mixins:[modalWindowMixin, filterBySearchFieldMixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    }
  },
  data() {
    return {
      dispatchCustomsCode:null,
      driver:"",
      form: {
        services:[],
        number_peg: "",
        ueo_doc_number: "УЭО-",
        ueo_doc_d_on: "",
        city_loading_location: "",
        driver_auth_doc_number: "",
        driver_auth_doc_d_on: "",
        dispatch_customs_name: "",
        arrival_date: "",
        delivery_address: "",
        currency_letter: "",
        invoices_numbers: "",
        other_terms_shipment: "",
        add_info: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      user:"auth/getUser",
      selected: 'transit/getSelected',
      shipmentIndex:'transit/getShipmentIndex',
      nsiTransportMarks: "transit/getNsiTransportMarks",
      customsPoints: "catalogs/getNsiCustomsPoints",
      currencies:"catalogs/getNsiCurrencies"
    })
  },
  watch: {
    show: {
      handler(value){
        if (value) {
          this.setFields()
        }
      },
      immediate:true
    }
  },
  methods: {
    getShipment(){
      return this.selected.ware_shipments[this.shipmentIndex] ?? null
    },
    setFields() {
      const shipment = this.getShipment()
      const { registration_details, driver} = this.selected
      const {dispatch_customs_code} = registration_details
      const {recipient}= shipment
      const {postal_index,country_letter,region,city, street_house, house, room} = recipient.recipient_json
      const delivery_address = [postal_index,country_letter,region,city, street_house, house, room].join(' ').trim()
      this.dispatchCustomsCode = dispatch_customs_code
      this.setDriver(driver)
      this.form = {
        ...this.form,
        delivery_address,
        arrival_date: moment().format('YYYY-MM-DD'),
        currency_letter: 'BYN'
      }
      if(this.selected.driver.driver_identity_doc_number === this.selected.filler.filler_json.identity_doc_number){
        this.form.driver_auth_doc_number = this.selected.filler.filler_json.auth_doc_number
        this.form.driver_auth_doc_d_on = this.selected.filler.filler_json.auth_doc_d_on
      }
    },
    setDriver({driver_first_name, driver_last_name, driver_middle_name}){
      this.driver = [driver_last_name,driver_first_name, driver_middle_name].join(' ').trim()
    },
    getPreparedForm(){
      return {
        ...this.form,
        dispatch_customs_name: getCustomsNameByCode(this.customsPoints, this.dispatchCustomsCode)
      }
    },
    submit(){
      const payload = convertEmptyStringsToNull(this.getPreparedForm())
      this.$store.dispatch('transit/getForwardingOrder', {
        payload,
        declarationId:this.selected.id,
        userId:this.user.user.id,
        divisionId:this.user.user.activeDivisionId
      }).then((res) => {
        downloadFile({
          data:res.data,
          type:"application/pdf",
          name:getFilenameFromContentDisposition(res)
        })
      }).catch(() => this.$error())
    }
  }
}
</script>
