<template>
  <v-row>
    <v-col cols="1">
      <v-text-field
        disabled
        :value="index"
        background-color="grey lighten-2"
        outlined
        dense
        hide-details
      />
    </v-col>
    <v-col cols="7">
      <v-autocomplete
        v-model="service.service_name"
        :items="servicesNames"
        background-color="white"
        outlined
        dense
        hide-details
      />
    </v-col>
    <v-col cols="2">
      <input-numeric
        v-model="service.service_sum"
        placeholder="Сумма"
        :fraction="2"
        background-color="white"
        outlined
        dense
        hide-details
      />
    </v-col>
    <v-col cols="2">
      <v-btn
        v-if="services.length > 1"
        width="40px"
        min-width="40px"
        text
        @click="$emit('remove', index)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="services.length -1 === index"
        width="40px"
        min-width="40px"
        text
        @click="$emit('add')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import {services as servicesNames} from './services'
import InputNumeric from "@/components/ui/input-numeric.vue";
export default {
  components:{
    InputNumeric
  },
  props:{
    index:{
      required:true,
      type:Number
    },
    services:{
      required:true,
      type:Array
    },
    value:{
      type:Object,
      required:true
    }
  },
  data(){
    return{
      servicesNames,
      service:{
        service_name:"",
        service_sum:""
      }
    }
  },
  watch:{
    service:{
      handler(service){
        this.$emit('update', {index: this.index, service})
      },
      deep:true
    },
    value:{
      handler(service){
        this.service = service
      },
      deep:true,
      immediate:true
    }
  }
}
</script>
