export const services = [
  "1.3.1. организация процесса перевозки грузов",
  "1.3.2. оформление транспортных (перевозочных), грузосопроводительных и иных документов, необходимых для перевозки груза;",
  "1.3.3. составление транзитной  декларации и  представление  от своего имени к таможенному оформлению товаров и транспортных средств с целью помещения под таможенную процедуру таможенного транзита;",
  "1.3.4. услуги, связанные с подготовкой груза к перевозке: определение массы груза, упаковка, затаривание, маркировка, пакетирование, сортировка груза;",
  "1.3.5. услуги, связанные с погрузкой (выгрузкой) груза: обеспечение выполнения погрузочно-разгрузочных работ, в том числе перевалки груза при смешанной перевозке, закрепление, укрытие, увязки груза, а также предоставление необходимых для этих целей приспособлений;",
  "1.3.6. сопровождение груза в процессе перевозки и иные услуги по обеспечению его сохранности;",
  "1.3.7. заключение со страховой организацией договоров добровольного страхования грузов в соответствии с законодательством Республики Беларусь;",
  "1.3.8. согласование схемы (маршрута, последовательности) перевозки грузов несколькими видами транспорта при смешанной перевозке;",
  "1.3.9. консолидация и деконсолидация отправок грузов;",
  "1.3.10. проверка количества мест, массы и состояние груза;",
  "1.3.11. хранение груза;",
  "1.3.12. прием груза в пункте назначения;",
  "1.3.13. уплата пошлин, сборов и других платежей, связанных с оказываемыми транспортно-экспедиционными услугами;",
  "1.3.14. осуществление расчетов с участниками транспортно-экспедиционной деятельности;",
  "1.3.15. консультирование по вопросам организации перевозок грузов;",
  "1.3.16. оказание информационных услуг, связанных с перевозкой груза;",
  "1.3.17. иные услуги, связанные с перевозкой груза.",
]