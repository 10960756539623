<template>
  <v-col cols="12">
    <label>Перечень транспортно-экспедиционных услуг</label>
    <services-item
      v-for="(service, index) in services"
      :key="index"
      :services="services"
      :value="service"
      :index="index"
      @update="updateService"
      @remove="removeService"
      @add="addService"
    />
  </v-col>
</template>
<script>
import ServicesItem from "@/components/transit/forwarding-order/services-item.vue";

export default {
  components: {ServicesItem},
  props: {
    value: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      services: []
    }
  },
  watch:{
    services:{
      handler(){
        this.$emit('input', this.services)
      },
      deep:true
    },
    value:{
      handler(services){
        this.services = services
      },
      deep:true
    },
  },
  created() {
    if(this.value.length){
      this.services = this.value
    }else {
      this.addService()
    }
  },
  methods: {
    addService(){
      const service = {
        service_name:"1.3.3. составление транзитной  декларации и  представление  от своего имени к таможенному оформлению товаров и транспортных средств с целью помещения под таможенную процедуру таможенного транзита;",
        service_sum:""
      }
      this.services.push(service)
    },
    updateService({index,service}) {
      this.services.splice(index, 1, service)
    },
    removeService(index){
      this.services.splice(index, 1)
    }

  }
}
</script>
